   
import React, { useState, useEffect } from "react";
import "./style.scss";
import moment from "moment";
//import { uuid } from 'uuidv4';
//import $ from "jquery";

import {
  Form,
  Input,
  Button,
  Modal,
  Select,
  AutoComplete,
  message,
  Divider,
  InputNumber,
  Tag,
  DatePicker,
} from "antd";

import {
  EditOutlined,
  CreditCardOutlined,
  DollarCircleOutlined,
  DeleteOutlined,
  UserOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import {
  getDataFromLocalStorage,
  checkUserAuthFromLocalStorage,
  //getSellInvoiceDataFromLocalStorage,
  saveDataIntoLocalStorage,
  //clearDataFromLocalStorage,
} from "../../../../utils/local-storage/local-store-utils";
import { useHistory } from "react-router-dom";
import Constants from "../../../../utils/constants/constants";
//import UrlConstants from "../../../../utils/constants/url-configs";
import * as ProductsApiUtil from "../../../../utils/api/products-api-utils";
import * as CustomersApiUtil from "../../../../utils/api/customer-api-utils";
import * as CouriersApiUtil from "../../../../utils/api/couriers-api-utils";
import * as SalesApiUtil from "../../../../utils/api/sales-api-utils";
import * as SetupApiUtil from "../../../../utils/api/setup-api-utils";
import * as Helpers from "../../../../utils/helpers/scripts";
import SellNestedProductsTable from "../../../organism/table/sell/sellNestedProductsTable";
import SellNestedProductsTableUpdatedTemplate from "../../../organism/table/sell/sellNestedProductsTableUpdatedTemplate";
import PrintSalesInvoiceTable from "./sellInvoice";
import PrintSalesInvoiceTableUpdatedTemplate from "./sellInvoiceUpdatedTemplate";



let localStorageCacheData = null;
let currentReceiptNumber = null;
 

function Sell() {
  const history = useHistory();
  const [form] = Form.useForm();
  const [costForm] = Form.useForm();
  const [saleInvoiceData, setSaleInvoiceData] = useState(null);
  const [productsSearchResult, setProductsSearchResult] = useState([]);
  const [selectedCustomerValue, setSelectedCustomerValue] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const [loading, setLoading] = useState(true);
  const [selectedProductId, setSelectedProductId] = useState("");
  const [productsTableData, setProductsTableData] = useState([]);
  const [registereProductsData, setRegistereProductsData] = useState([]);
  const [couriersData, setCouriersData] = useState([]);
  const [customersData, setCustomersData] = useState([]);
  const [localStorageData, setLocalStorageData] = useState("");
  const [isMopModalVisible, setIsMopModalVisible] = useState(false);
  const [selectedCutomer, setSelectedCutomer] = useState("");
  const [productsTotalAmount, setProductsTotalAmount] = useState(0);
  const [productsTotalQuantity, setProductsTotalQuantity] = useState(0);
  const [syncStatus, setSyncStatus] = useState(false);
  const [templateData, setTemplateData] = useState(null);
  //const [currentInvoiceNumber, setCurrentInvoiceNumber] = useState(0);
  const [networkStatus, setNetworkStatus] = useState(window.navigator.onLine);   //imp
  const [currentLoggedSuperUserId, setCurrentLoggedSuperUserId] = useState("");



  const { Option } = Select;

  const todayDate = moment();
  const dateFormat = "yyyy/MM/DD";
  //const timeFormat = "hh:mm:ss A";     imp prev version
  const timeFormat = "HH:mm:ss";

  let mounted = true;


  useEffect(() => {

    /*-----------set user cache data------------*/
    let readFromLocalStorage = getDataFromLocalStorage(
      Constants.USER_DETAILS_KEY
    );
    readFromLocalStorage = readFromLocalStorage.data
      ? readFromLocalStorage.data
      : null;
    if (readFromLocalStorage) {
      if (
        checkUserAuthFromLocalStorage(Constants.USER_DETAILS_KEY).authentication
      ) {
        localStorageCacheData = readFromLocalStorage;                        //vvv imp
        setLocalStorageData(readFromLocalStorage);
        setCurrentLoggedSuperUserId(readFromLocalStorage.auth.super_user);   //imp new one working correctly
        fetchApisData(readFromLocalStorage.auth.current_store);              //vvvimp 
      }
    }
    /*-----------set user cache data------------*/


    /*-----------------network status hooks----------------------*/
    window.addEventListener('offline', function (e) {
      setNetworkStatus(false);
      //console.log('offline');
    });
    window.addEventListener('online', function (e) {
      setNetworkStatus(true);
      //console.log('online');
    });
    
    
    /*---------------------------------------*/

    return () => {
      console.log("unmount");
      saveDataIntoLocalStorage(Constants.SELL_CURRENT_INVOICE_KEY, null);    //imp new one 
      mounted = false;
    }

  }, []);



  const fetchApisData = async (currentStoreId) => {
    document.getElementById('app-loader-container').style.display = "block";

    await Promise.all(
      [
        fetchRegisteredProductsData(),
        fetchCouriersData(),
        getUserStoreData(currentStoreId),
        getCurrentInvoiceNumber(),
      ]);

    document.getElementById('app-loader-container').style.display = "none";

    if (history.location.selected_invoice_data === undefined) {
      startInvoice();
    }
    if (history.location.selected_invoice_data !== undefined) {
      handleReturnedSaleProcess();
    }

  };


 
  
  const handleReturnedSaleProcess = async () => {
    //console.log("inside-processs-return-handler");
    let selectedViewedInvoice = history.location.selected_invoice_data;
    //console.log("invoice-imp-for-returns", selectedViewedInvoice );
    let tmpInvoice = await createNewInvoice("returns sale", selectedViewedInvoice.invoice_details);
    let rt = false;
    let saleStatus = ""
    if (selectedViewedInvoice.status_invoice == 0) {
      rt = true;
      tmpInvoice.saleStatus = "returned";
    }
    if (selectedViewedInvoice.status_invoice == 1) {
      tmpInvoice.saleStatus = "parked";
    }
    tmpInvoice.OldInvoiceNo = history.location.selected_invoice_id;
    tmpInvoice.discountVal = selectedViewedInvoice.invoice_details.discount_percentage;    /*imp pending for now */
    tmpInvoice.products = selectedViewedInvoice.invoices;
    tmpInvoice.return = rt;
    if (selectedViewedInvoice.hasCustomer == true) {
      tmpInvoice.customer = selectedViewedInvoice.customer;
      tmpInvoice.hasCustomer = true;
    }

    saveDataIntoLocalStorage(Constants.SELL_CURRENT_INVOICE_KEY, tmpInvoice);
    //console.log(tmpInvoice);
    updateCart(tmpInvoice);      //imp new one ver //imp to pass true in case of loacal invoice total paid value 


  };


  const fetchCouriersData = async () => {
    const couriersViewResponse = await CouriersApiUtil.viewAllCouriers();
    console.log("couriersViewResponse:", couriersViewResponse);

    if (couriersViewResponse.hasError) {
      console.log("Cant fetch couriers -> ", couriersViewResponse.errorMessage);
    } else {
      if (mounted) {     //imp if unmounted
        //message.success(couriersViewResponse.message, 3);
        setCouriersData(
          couriersViewResponse.courier.data || couriersViewResponse.courier
        );
      }
    }
  };

  const  fetchRegisteredProductsData = async () => {
    //document.getElementById('app-loader-container').style.display = "block";
    const productsDiscountsViewResponse = await ProductsApiUtil.getFullRegisteredProducts();
    console.log(
      " productsDiscountsViewResponse:",
      productsDiscountsViewResponse
    );

    if (productsDiscountsViewResponse.hasError) {
      console.log(
        "Cant fetch registered products Data -> ",
        productsDiscountsViewResponse.errorMessage
      );
      message.warning(productsDiscountsViewResponse.errorMessage, 3);
      history.push({
        pathname: "/register/salesHistory",
      });
      setLoading(false);
      //document.getElementById('app-loader-container').style.display = "none";
    } else {

      if (mounted) {     //imp if unmounted
        //message.success(productsDiscountsViewResponse.message, 3);
        /*-------for filtering products--------*/
        var products =
          productsDiscountsViewResponse.products.data ||
          productsDiscountsViewResponse.products;

        products.forEach((e) => {
          e.original_sale_price = e.product_sale_price;
          e.product_sale_price = parseFloat(e.discounted_price).toFixed(2);
        });

        for (let i in products) {
          let searchName = products[i].product_name;

          if (Helpers.var_check_updated(products[i].product_variant1_value)) {
            searchName += " / " + products[i].product_variant1_value;
          }
          if (Helpers.var_check_updated(products[i].product_variant2_value)) {
            searchName += " / " + products[i].product_variant2_value;
          }

          /*--------------------new version-------------------------------*/
          /*searchName += (products[i].product_variant1_value ? `/ ${products[i].product_variant1_value}` : "")
            + (products[i].product_variant2_value ? `/ ${products[i].product_variant2_value}` : ""); */
          /*--------------------new version-------------------------------*/

          products[i].searchName = searchName;
          //products[i].qty = 0;   //imp but not set here ,set at addorder
          //products[i].original_tax_value = products[i].tax_value;    //imp prev version comment this here
        }

        setRegistereProductsData(products);

        /*-------for filtering products--------*/
        //setRegistereProductsData(productsDiscountsViewResponse.products);

        setLoading(false);
        //document.getElementById('app-loader-container').style.display = "none";

      }

    }
  };



  const getCurrentInvoiceNumber = async (returnSale = "") => {
    const getInvoieNumberResponse = await SalesApiUtil.getCurrentInvoiceNumber();
    console.log("getInvoieNumberResponse:", getInvoieNumberResponse);

    if (getInvoieNumberResponse.hasError) {
      console.log("Cant getInvoieNumber -> ", getInvoieNumberResponse.errorMessage);
      message.error(getInvoieNumberResponse.errorMessage + " Invoie Number is not defined", 3);
      currentReceiptNumber = null;  //imp
    } else {
      if (mounted) {     //imp if unmounted
        //message.success(couriersViewResponse.message, 3);
        let invNoRes = getInvoieNumberResponse.invoice_number;
        currentReceiptNumber = invNoRes;  //imp

      }
    }

  }


  const handleCustomerSearch = async (searchValue) => {

    setSelectedCustomerValue(searchValue);      //imp  working correctly

    const customersSearchResponse = await CustomersApiUtil.searchCustomer(
      searchValue
    );
    console.log("customersSearchResponse:", customersSearchResponse);

    if (customersSearchResponse.hasError) {
      console.log(
        "Cant search Customer -> ",
        customersSearchResponse.errorMessage
      );
    } else {
      //message.success(customersSearchResponse.message, 3);
      setCustomersData(
        customersSearchResponse.customers.data ||
          customersSearchResponse.customers
      );
    }
  };

  const handleSearch = (value) => {
    setSelectedValue(value);

    var currValue = value;
    currValue = currValue.toLowerCase();
    if (currValue === "") {
      setProductsSearchResult([]);
    } else {
      const filteredData = registereProductsData.filter((entry) => {
        let searchValue = entry.searchName;
        searchValue = searchValue.toLowerCase();
        let productSku = entry.product_sku;
        productSku = productSku.toLowerCase();

        return searchValue.includes(currValue) || productSku.includes(currValue);
      });
      setProductsSearchResult(filteredData);
    }
  };

  const handleSelect = (value, option) => {
    //console.log(value);
    //console.log("imp", option);
    setSelectedValue(option.children);    //working correctly
    setSelectedProductId(value);       //passes productuinqId
    handleAddProduct(value);           //imp new one 
    setSelectedValue("");              //imp new one
    setProductsSearchResult([]);       //imp vv new one
  };

  const handleCustomerDelete = () => {
    if (saleInvoiceData.method == "Customer Layby") {
      saleInvoiceData.method = "Cash";
    }
    saleInvoiceData.customer = {};
    setSelectedCutomer("");
    saleInvoiceData.hasCustomer = false;
    setSaleInvoiceData(saleInvoiceData); //imp
    updateCart(saleInvoiceData);
  };

  const handleCustomerSelect = (customerId, option) => {
    //console.log(customerId);
    //console.log("imp-cus", option);
    setSelectedCustomerValue(option.children); //working correctly

    customersData.forEach((cus) => {
      if (cus.customer_id === customerId) {
        setSelectedCutomer(cus); //passes customer
        //console.log(cus);
        saleInvoiceData.customer = cus;
        saleInvoiceData.hasCustomer = true;
        return 0;
      }
    });

    //setSaleInvoiceData(saleInvoiceData);
    updateCart(saleInvoiceData);
  };


  const selectCustomerOnEnter = (event) => {
    //console.log(event);
    if (event.key === "Enter") {
      //console.log(" enter");
      customersData.forEach((cus) => {
        if ((cus.customer_name).toLowerCase()  === selectedCustomerValue.toLowerCase()) {
          setSelectedCutomer(cus);     //passes customer
          //console.log(cus);
          saleInvoiceData.customer = cus;
          saleInvoiceData.hasCustomer = true;
          return 0;
        }
      });

      updateCart(saleInvoiceData);     //imp
   
    } else {
      //console.log("not enter");
    }

  };


  const handleDeleteSale = async (e) => {
    if (productsTableData.length === 0) {
      message.error("No Products Added", 4);
      return;
    }
    saveDataIntoLocalStorage(Constants.SELL_CURRENT_INVOICE_KEY, null);
    form.setFieldsValue({
      tax_value:  "",
    }); //imp
    document.getElementById('app-loader-container').style.display = "block";
    await getCurrentInvoiceNumber();
    document.getElementById('app-loader-container').style.display = "none";
    let newInvoice = await createNewInvoice("clear sale");
    updateCart(newInvoice);
  };


  
  const handleDeadSale = async (e) => {

    let oldInvoiceId = saleInvoiceData.OldInvoiceNo || "";
    document.getElementById('app-loader-container').style.display = "block";
    const salesHistoryDeadResponse = await SalesApiUtil.deadSaleHistory(
      oldInvoiceId,
    );
    console.log('salesHistoryViewResponse:', salesHistoryDeadResponse);
    if (salesHistoryDeadResponse.hasError) {
      console.log('Cant fetch registered products Data -> ', salesHistoryDeadResponse.errorMessage);
      document.getElementById('app-loader-container').style.display = "none";
      message.warning(salesHistoryDeadResponse.errorMessage, 3);
    }
    else {

      if (mounted) {     //imp if unmounted
        ////////////////////////update local////////////////////////////
        saveDataIntoLocalStorage(Constants.SELL_CURRENT_INVOICE_KEY, null);
        form.setFieldsValue({
          tax_value: "",
        }); //imp
        await getCurrentInvoiceNumber();
        let newInvoice = await createNewInvoice("dead sale");
        updateCart(newInvoice);
        ////////////////////////update local////////////////////////////
        document.getElementById('app-loader-container').style.display = "none";
        //message.success(salesHistoryDeadResponse.message, 2);
        history.push({
          pathname: "/register/salesHistory",
        });

      }
    }


  };


  const handleDiscountChange = (e) => {
    saleInvoiceData.isDiscount = true;
    updateCart(saleInvoiceData);
  };

  const changeMethodOfPayment = (methodName) => {
    saleInvoiceData.method = methodName;
    setSaleInvoiceData(saleInvoiceData);
    setIsMopModalVisible(false);
    saveDataIntoLocalStorage(Constants.SELL_CURRENT_INVOICE_KEY, saleInvoiceData);    //imp new one
  };

  const showModal = () => {
    setIsMopModalVisible(true);
  };

  const handleOk = () => {
    setIsMopModalVisible(false);
  };

  const handleCancel = () => {
    setIsMopModalVisible(false);
  };

  const handleTaxCategoryChange = (taxValue) => {
    //console.log(taxValue);
    if (taxValue == 16) {
      saleInvoiceData.taxCategory = "simple_tax";
    }
    if (taxValue == 5) {
      saleInvoiceData.taxCategory = "punjab_food_fbr";
    }

    updateCart(saleInvoiceData); //imp
  };

  const handleCourierChange = (value) => {
    //console.log(value);
    const clonedInvoice = { ...saleInvoiceData };
    clonedInvoice.courier_code = value;
    saveDataIntoLocalStorage(Constants.SELL_CURRENT_INVOICE_KEY, clonedInvoice); //imp
    setSaleInvoiceData(clonedInvoice);
  };
  

  const onInvoiceDateChange = (value, dateString) => {
    //console.log(value+"-"+dateString);
    //let today = moment(new Date()).format(dateFormat);  //current date
    let currentTime = moment().format(timeFormat);  //current time
    if (value) {
      const clonedSaleInvoiceData = { ...saleInvoiceData };
      clonedSaleInvoiceData.dateTime = dateString + " " + currentTime;
      setSaleInvoiceData(clonedSaleInvoiceData);
    }
  };


  function disabledDate(current) {
    // Can not select days after today
    let disableToday = true;
    if(current > moment().startOf('day') && current < moment().endOf('day')) {
      //console.log("today");
      disableToday = false;
    }
    if(registerScopeFilter(localStorageData.user_info)) {  //if cashier
      return current && disableToday;
    }
    else {
      return current && current > moment().endOf('day');
    }

  }


  const handleInvoiceNoteChange = (e) => {
    //console.log(e.target.value);
    const clonedInvoice = { ...saleInvoiceData };
    clonedInvoice.reference = e.target.value;
    saveDataIntoLocalStorage(Constants.SELL_CURRENT_INVOICE_KEY, clonedInvoice); //imp
    setSaleInvoiceData(clonedInvoice);
  };

  const handlePaidChange = (value) => {
    let inputValue = (value);
    //var costFormValues = costForm.getFieldsValue();
    //console.log(costFormValues);
    let paidAmount;
    if (Helpers.var_check(inputValue) && !isNaN(inputValue)) {
      paidAmount = inputValue;
    } else {
      paidAmount = 0;
    }

    const clonedInvoice = { ...saleInvoiceData };
    clonedInvoice.payed = paidAmount;
    
    //costForm.setFieldsValue({ paid: clonedInvoiceData && clonedInvoiceData.payed }); //imp
    saveDataIntoLocalStorage(Constants.SELL_CURRENT_INVOICE_KEY, clonedInvoice);    //imp
    setSaleInvoiceData(clonedInvoice);

  };

  const handleChangeProductsData = (
    productsData,
    productsTotalQuantity = 0
  ) => {
    calculateProductsTotalQuantityAndAmount(productsData);
    //setProductsTableData(productsData);   //calling in updatecart now
    saleInvoiceData.products = productsData;
    updateCart(saleInvoiceData); //imp
  };

  const handleAddProduct = (selectedProdId = selectedProductId) => {
    //var formValues = form.getFieldsValue();

    var productExistsCheck = false;
    var newData = [...productsTableData];
    //productsTableData
    const index = registereProductsData.findIndex(
      (item) => selectedProdId == item.product_id
    );

    if (index > -1) {
      //deep copy
      const selectedItem = JSON.parse(
        JSON.stringify(registereProductsData[index])
      );
      console.log(selectedItem);
      productsTableData.forEach((p) => {
        if (p.product_id === selectedItem.product_id) {
          productExistsCheck = true;
          p.qty += 1;
        }
      }); //end of for loop

      if (productExistsCheck) {
        calculateProductsTotalQuantityAndAmount(productsTableData);
        //setProductsTableData(productsTableData);  // caling in updatecart now imppp
        //update cart  imp
        saleInvoiceData.products = productsTableData;
        saveDataIntoLocalStorage(
          Constants.SELL_CURRENT_INVOICE_KEY,
          saleInvoiceData
        ); //imp
        updateCart(saleInvoiceData);

        //update cart  imp
      }
      if (!productExistsCheck) {
        selectedItem.qty = 1;
        newData.push(selectedItem);
        //console.log("imp1-table", newData);
        calculateProductsTotalQuantityAndAmount(newData);
        //setProductsTableData(newData);  // callng in updatecart now imppp
        //update cart  imp
        saleInvoiceData.products = newData;
        saveDataIntoLocalStorage(
          Constants.SELL_CURRENT_INVOICE_KEY,
          saleInvoiceData
        ); //vvimp
        updateCart(saleInvoiceData);

        //update cart  imp
      }
    } //end of top first if
  };

  const calculateProductsTotalQuantityAndAmount = (data) => {
    var productsTotalQuantity = 0;
    var productsTotal = 0;

    const newData = [...data];
    newData.forEach((item) => {
      productsTotal =
        productsTotal +
        parseFloat(item.qty || 0) * parseFloat(item.product_sale_price);
      productsTotalQuantity = productsTotalQuantity + item.qty;
    });

    setProductsTotalQuantity(productsTotalQuantity);
    setProductsTotalAmount(productsTotal);
  };


  const handlePayBill = async (status, check = false) => {
    //console.log("changed", formData);
    //console.log(status);

    if (productsTableData.length === 0) {
      message.error("No Products Added", 4);
      return;
    }

    saleInvoiceData.status = status; //imp
    delete saleInvoiceData["saleStatus"];    //imp new one before inserting to queue

    if (saleInvoiceData.hasCustomer && saleInvoiceData.method === "Customer Layby") {   //if customer selected
      let invoiceTotal = parseFloat(
        saleInvoiceData.total - saleInvoiceData.discountAmount
      ).toFixed(2);
      if (parseFloat(saleInvoiceData.customer.balance) < invoiceTotal) {
        //message.warning("Insufficient Balance", 3);      //imp 
        //return;                                          //imp
      }
    } //end of inner if (customer selected)

    let AddInvoiceRequestRespone;

    if (status === "close" || status === "hold" ) {
      document.getElementById('app-loader-container').style.display = "block";
      // receipt add and print function
      AddInvoiceRequestRespone = await addInvoiceRequest(saleInvoiceData);
      if(AddInvoiceRequestRespone === "success") {
        saveDataIntoLocalStorage(Constants.SELL_CURRENT_INVOICE_KEY, null);
        await getCurrentInvoiceNumber();  //imp after add request
      }
      document.getElementById('app-loader-container').style.display = "none";
      if(AddInvoiceRequestRespone === "success") {
        if(status === "close") {
          printSalesOverview();  //imp first print then reset fields
        }
        resetFiledsOnNewInvoice();  //after print must
      }
    }

  };


  const resetFiledsOnNewInvoice = async () => {

    setSelectedCutomer("");
    setSelectedCustomerValue("")   //imp new one
    setProductsTableData([]);      //vvimp new one
    
    form.setFieldsValue({
      invoiceNote: "",
    }); //imp
    form.setFieldsValue({
      invoiceDate:  todayDate,
    }); //imp  
    form.setFieldsValue({
      tax_value:  "",
    }); //imp
    costForm.setFieldsValue({
      discounted_value:  0,
    }); //imp
    form.setFieldsValue({
      courier_code:  "",
    }); //imp


    let newInvoice = await createNewInvoice(); //new invoice again
    /*----------new ver for checkout items updation----------*/
    calculateProductsTotalQuantityAndAmount([]);
    /*----------new ver for checkout items updation----------*/
    updateCart(newInvoice);

  };



  const printSalesOverview = () => {
    let previewSalesInvoiceHtml = document.getElementById("printSalesTable");
    if (!previewSalesInvoiceHtml) { return; }
    previewSalesInvoiceHtml = document.getElementById("printSalesTable").innerHTML;

    var doc =
      '<html><head><title></title><link rel="stylesheet" type="text/css" href="/printInvoice.scss" /></head><body onload="window.print(); window.close();" >' +
      previewSalesInvoiceHtml +
      "</body></html>";
    /* NEW TAB OPEN PRINT */
    var popupWin = window.open(
      "",
      "_blank",
      "toolbar=no,scrollbars=yes,resizable=yes,top=100,left=400,width=500,height=500"
    );
    popupWin.document.open();
    //window.print(); window.close(); //'width: 80%, height=80%'
    popupWin.document.write(doc);
    popupWin.document.close();  //vvimp for autoprint
  };

  ////////////////imp funcyionality////////////////////

  const startInvoice = async () => {
    //console.log("start-invoice");
    let readFromLocalStorage = getDataFromLocalStorage(
      Constants.SELL_CURRENT_INVOICE_KEY
    );
    readFromLocalStorage = readFromLocalStorage.data
      ? readFromLocalStorage.data
      : null;

    let currentInvoice = {};

    if (readFromLocalStorage) {
      //currentInvoice = readFromLocalStorage;   //imp prev version
      /*-------------------------new version-------------------------*/
      for (var key in readFromLocalStorage) {
        if (readFromLocalStorage.hasOwnProperty(key)) {
          //console.log(key + "-" + readFromLocalStorage[key]);
          currentInvoice[key] = readFromLocalStorage[key];
        }
      }
      /*-------------------------new version-------------------------*/
      //console.log("imp-here-local-storage");
      updateCart(currentInvoice, true);         //imp to pass true in case of loacal invoice total paid value 

    } else {
      currentInvoice = await createNewInvoice();  //imp await to be here
      //console.log("imp->currentInvoice", currentInvoice);
    
      //console.log("see1", currentInvoice);
      updateCart(currentInvoice);
    }

  };

  

  ////////////////imp functionality////////////////////

  // Invoice addInvoiceRequest Function
  const addInvoiceRequest = async (saleInvoiceData) => {

    let localCurrentInvoiceData = getDataFromLocalStorage(
      Constants.SELL_CURRENT_INVOICE_KEY
    );

    localCurrentInvoiceData = localCurrentInvoiceData.data
      ? localCurrentInvoiceData.data
      : null;

    console.log("imp-local-invoice-inside-request-add->", saleInvoiceData);

    //document.getElementById('app-loader-container').style.display = "block";
    const registerInvoiceResponse = await SalesApiUtil.registerInvoice([{...saleInvoiceData}]);
    console.log(
      " registerInvoiceResponse:",
      registerInvoiceResponse
    );

    if (registerInvoiceResponse.hasError) {
      console.log(
        "Cant add registered Invoice Data -> ",
        registerInvoiceResponse.errorMessage
      );
      console.log("Fail");
      //document.getElementById('app-loader-container').style.display = "block";
      message.error(registerInvoiceResponse.errorMessage, 3);
      return "failure";  //imp message must be exact
    }
    else {
      let invoicesData = registerInvoiceResponse.Invoices_added;
      console.log("add-invoice-request-response", invoicesData);
      //document.getElementById('app-loader-container').style.display = "none";
      return "success";  //imp message must be exact
    }

  }

  ////////////////imp functionality////////////////////

  ////////////////imp functionality////////////////////

  async function createNewInvoice(clearSaleVal = "", returnsSaleObj = null) {

    /*-----------imp new ver for actuall receipt no------------*/
    let currInvoiceNo;
    if (networkStatus) { //online
      if (clearSaleVal === "clear sale" || clearSaleVal ===  "dead sale") {
        currInvoiceNo = currentReceiptNumber;
      }
      else {
        if(clearSaleVal === "returns sale") {
          currInvoiceNo = returnsSaleObj && parseInt(returnsSaleObj.invoice_show_id);
        }
        else {
          currInvoiceNo = currentReceiptNumber;
        }
      }
      //console.log("currInvoiceNo", currInvoiceNo);
    }
    /*-----------imp new ver for actuall receipt no------------*/


    // $scope.invoice
    var data = {};
    data.isDiscount = false;
    data.dateTime = moment(new Date()).format("yyyy/MM/DD HH:mm:ss");      //imp prev ver
    //data.invoiceNo = Helpers.uniqid();                                   //imp prev ver
    //data.invoiceNo = Helpers.makeUniqueReceiptId(20);                    //generate 20 chars aplanumeric code
    data.invoiceNo  = currInvoiceNo;
    //data.invoiceNo = uuid();
    data.OldInvoiceNo = "";
    data.store_id = localStorageCacheData.auth.store_random;
    data.user_id = localStorageCacheData.user_info.user_random;
    data.method = "Cash";
    data.status = "current";
    data.products = [];
    data.tax = 0;
    data.total = 0;
    data.sub_total = 0;
    data.payed = 0;
    data.return = false;
    data.customer = {};
    data.discountVal = 0;
    data.hasCustomer = false;
    data.discountAmount = 0;
    data.courier_code = "";
    data.saleStatus = "new_sale";
    data.currentInvoiceNumber = currInvoiceNo;


    setSaleInvoiceData(data);

    return data;

  }


  function updateCart(invoiceData, localStorageInvoiceTotalPayedCheck = false) {
    var formValues = form.getFieldsValue();
    var costFormValues = costForm.getFieldsValue();

    //console.log(formValues);
    //console.log(costFormValues);

    //var clonedInvoiceData = JSON.parse(JSON.stringify(invoiceData));
    //var clonedInvoiceData = { ...invoiceData };   //imp prev version
    let clonedInvoiceData = {};
    /*-------------------new version----------------*/
    for (var key in invoiceData) {
      if (invoiceData.hasOwnProperty(key)) {
          //console.log(key + "-" + invoiceData[key]);
          clonedInvoiceData[key] = invoiceData[key];
      }
   }
    /*-------------------new version---------------*/

    for (var key in invoiceData) {
      delete invoiceData[key];
    }

    var tableProducsData = clonedInvoiceData ? clonedInvoiceData.products : [];

    clonedInvoiceData.tax = 0;
    clonedInvoiceData.sub_total = 0;
    clonedInvoiceData.total = 0;

    for (let i in tableProducsData) {
      if (Helpers.var_check(tableProducsData[i].qty))
        tableProducsData[i].qty = parseInt(tableProducsData[i].qty);
      else tableProducsData[i].qty = 0;
      if (Helpers.var_check(tableProducsData[i].product_sale_price))
        tableProducsData[i].product_sale_price = parseFloat(
          parseFloat(tableProducsData[i].product_sale_price).toFixed(2)
        );
      else tableProducsData[i].product_sale_price = 0;

      if (!tableProducsData[i].original_tax_value)
        tableProducsData[i].original_tax_value =
        tableProducsData[i].tax_value;


      tableProducsData[i].tax_value =
      clonedInvoiceData.taxCategory === 'punjab_food_fbr'
          ? 5
          : tableProducsData[i].original_tax_value;


      clonedInvoiceData.tax +=
        ((tableProducsData[i].tax_value) *
          (tableProducsData[i].qty * tableProducsData[i].product_sale_price)) /
        100;


      clonedInvoiceData.sub_total +=
        tableProducsData[i].product_sale_price * tableProducsData[i].qty;
    } //enf of for loop

    clonedInvoiceData.products = tableProducsData; //imp
    setProductsTableData(tableProducsData); //vvimp

    clonedInvoiceData.total +=
      clonedInvoiceData.tax + clonedInvoiceData.sub_total;
    clonedInvoiceData.tax = parseFloat(
      parseFloat(clonedInvoiceData.tax).toFixed(2)
    );
    clonedInvoiceData.sub_total = parseFloat(
      parseFloat(clonedInvoiceData.sub_total).toFixed(2)
    );
    clonedInvoiceData.total = parseFloat(
      parseFloat(clonedInvoiceData.total).toFixed(2)
    );

   
    /*-------------------new version----------------------------------------*/
    let discountedInputValue = 0 ;
    if(costFormValues.discounted_value){
      discountedInputValue = costFormValues.discounted_value;
    }
    else if(clonedInvoiceData.discountVal){
      discountedInputValue = clonedInvoiceData.discountVal;
    }
    /*-------------------new version----------------------------------------*/

    /*let discountedInputValue = (Helpers.var_check(costFormValues.discounted_value)) ? costFormValues.discounted_value
      : (clonedInvoiceData.discountVal) ? clonedInvoiceData.discountVal : 0;   imp prev version */ 
    

    discountedInputValue = parseInt(discountedInputValue).toFixed(2);
    //console.log(discountedInputValue);
    discountedInputValue = parseFloat(discountedInputValue);

    clonedInvoiceData.discountVal = discountedInputValue;

    clonedInvoiceData.discountAmount = parseFloat(
      ((discountedInputValue * clonedInvoiceData.total) / 100).toFixed(2)
    );


    //console.log(localStorageInvoiceTotalPayedCheck);

    if (!localStorageInvoiceTotalPayedCheck) {   //imp check for local
      clonedInvoiceData.payed = parseFloat(
        parseFloat(
          clonedInvoiceData.total - clonedInvoiceData.discountAmount
        ).toFixed(2)
      );
    }


    setSaleInvoiceData(clonedInvoiceData); //imp
    //console.log(clonedInvoiceData);
    //console.log(clonedInvoiceData.payed);


    costForm.setFieldsValue({
      paid: clonedInvoiceData && clonedInvoiceData.payed,
      discounted_value: clonedInvoiceData && clonedInvoiceData.discountVal
    }); //imp


    if (tableProducsData.length > 0) {
      saveDataIntoLocalStorage(Constants.SELL_CURRENT_INVOICE_KEY, clonedInvoiceData);  //imp
    }

    //saveDataIntoLocalStorage("current_invoice", clonedInvoiceData);   //imp

  }



  const getUserStoreData = async (storeId) => {
    //document.getElementById('app-loader-container').style.display = "block";
    const getOutletViewResponse = await SetupApiUtil.getOutlet(storeId);
    console.log('getOutletViewResponse:', getOutletViewResponse);

    if (getOutletViewResponse.hasError) {
      console.log('Cant fetch Store Data -> ', getOutletViewResponse.errorMessage);
      //message.warning(getOutletViewResponse.errorMessage, 3);
      //document.getElementById('app-loader-container').style.display = "none";
    }
    else {
      let selectedStore = getOutletViewResponse.outlet;
      //message.success(getOutletViewResponse.message, 3);
      getTemplateData(selectedStore.template_id);   //imp to get template data

    }
  }



  const getTemplateData = async (templateId) => {
    
    const getTepmlateResponse = await SetupApiUtil.getTemplate(templateId);
    console.log('getTepmlateResponse:', getTepmlateResponse);

    if (getTepmlateResponse.hasError) {
      console.log('Cant get template Data -> ', getTepmlateResponse.errorMessage);
      //message.warning(getTepmlateResponse.errorMessage, 3);
      //document.getElementById('app-loader-container').style.display = "none";
    }
    else {
      var receivedTemplateData = getTepmlateResponse.template;
      //message.success(getTepmlateResponse.message, 3);
      setTemplateData(receivedTemplateData);
      //document.getElementById('app-loader-container').style.display = "none";

    }
  }


  const SelectProductOnEnter = (event) => {
    //console.log(event);
    if (event.key === "Enter") {
      //console.log(" enter");
      //console.log(selectedValue);   //imp new searched value
      let foundObj = productsSearchResult.find(obj => {
        return obj.product_sku === selectedValue || obj.product_name === selectedValue;
      });
      if (foundObj) {
        //console.log("found");
        //console.log(foundObj);
        handleAddProduct(foundObj.product_id);          //imp new one
        setSelectedValue("");                           //imp new one
        setProductsSearchResult([]);                    //imp new one
      }
      else {
        //console.log("not found");
      }

    } else {
      //console.log("not enter");
    }

  };


  const registerScopeFilter = (localUserInfo) => {
    if (!localUserInfo) { return; }
    // console.log(localUserInfo);
    if (localUserInfo.user_role == 'cashier') {
      return true;
    }
    else {
      return false;
    }
  };



  const onFinish = (values) => {
    console.log("Success:", values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  let WomensWearAccountCheck =  Helpers.WomensWearSuperUserAccountIds.includes(currentLoggedSuperUserId);


  console.log(saleInvoiceData);
  //console.log(uuid());
  //console.log("currentReceiptNumber", currentReceiptNumber);
  //console.log(window.navigator.onLine);    //imp for online status



 

  return (
    <>
      <div className='page sell'>
        {/* Left */}
        <div className='info'>
          <Form
            form={form}
            name='basic'
            layout='vertical'
            initialValues={{
              remember: true,
              invoiceDate: todayDate,    //imp to set
              
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >

            {!networkStatus && <Tag color="#f50">Offline</Tag>}
            
            <Form.Item label='Search for products'>

              {(syncStatus && networkStatus) &&
                <small className="register-sync-pull-right" >
                <SyncOutlined spin />
                Synicing Sales
               </small>}

              <AutoComplete
                style={{ width: "100%" }}
                className="info__register-sell-drop-down-menu"
                dropdownMatchSelectWidth={250}
                value={selectedValue}
                onSearch={handleSearch}
                onSelect={handleSelect}
                placeholder='select a product'
                onKeyDown={SelectProductOnEnter}
                autoFocus={true}
              >
                {productsSearchResult &&
                  productsSearchResult.map((item) => (
                    <Option key={item.product_id} value={item.product_id}>
                      {item.searchName}
                    </Option>
                  ))}
              </AutoComplete>
            </Form.Item>

            {/* <Button
              type='default'
              className='add-product-btn'
              onClick={handleAddProduct}
            >
              Add
            </Button> */}

            <Form.Item label='Courier' name='courier_code'>
              <Select 
                onChange={handleCourierChange}
                placeholder="Select Courier"
                showSearch    //vimpp to seach
                optionFilterProp="children"
                filterOption={(input, option) => {
                  //console.log(option);
                  return (
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0)
                }}
                filterSort={(optionA, optionB) =>
                  optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                }

              >
                {couriersData.map((obj, index) => {
                  return (
                    <Option key={obj.courier_id} value={obj.courier_code}>
                      {obj.courier_name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              label='Invoice Date'
              name='invoiceDate'
            >
              <DatePicker  className='select-w-100'
               onChange={onInvoiceDateChange}
               format={dateFormat}
               disabledDate={disabledDate}

              />
            </Form.Item>
            <Form.Item label='Tax Category' name='tax_value'>
              <Select
                onChange={handleTaxCategoryChange}
                placeholder="Select Tax"
                showSearch    //vimpp to seach
                optionFilterProp="children"
                filterOption={(input, option) => {
                  //console.log(option);
                  return (
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0)
                }}
                filterSort={(optionA, optionB) =>
                  optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                }

              >
                <Option key='1' value={16}>
                  Simple
                </Option>
                <Option key='2' value={5}>
                  FBS
                </Option>
              </Select>
            </Form.Item>
            <Form.Item
              label='Invoice Note'
              name='invoiceNote'
              onChange={handleInvoiceNoteChange}
            >
              <Input placeholder='input Invoice Note' />
            </Form.Item>
          </Form>
        </div>
        {/* Left */}

        {/* Right */}
        <div className='checkout'>
          <Form
            form={costForm}
            name='basic'
            layout='vertical'
            initialValues={{
              discounted_value: 0,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <div className='checkout__header'>
              <h3>
                Checkout &nbsp; (
                {/*saleInvoiceData && saleInvoiceData.products
                  ? saleInvoiceData.products.length
                  : 0 */}
                {productsTotalQuantity}
                )Items
              </h3>

              <div className='header__btns'>
                {(saleInvoiceData && saleInvoiceData.saleStatus !== "returned") &&
                  <Button
                    type='primary'
                    onClick={() => handlePayBill("hold")}
                    className='custom-btn custom-btn--primary'
                  >
                    Park Sale
                  </Button>}
                <Button onClick={handleDeleteSale}>Clear Sale</Button>
                {(saleInvoiceData && saleInvoiceData.saleStatus === "parked") &&
                  <Button type='primary' danger onClick={handleDeadSale} disabled={!networkStatus}>
                    Dead Sale
                  </Button>}

              </div>
            </div>

            <Form.Item>
              <AutoComplete
                style={{ width: "100%" }}
                dropdownMatchSelectWidth={250}
                value={selectedCustomerValue}
                onSearch={handleCustomerSearch}
                onSelect={handleCustomerSelect}
                placeholder='select customer'
                onKeyDown={selectCustomerOnEnter}
              >
                {customersData &&
                  customersData.map((item) => (
                    <Option key={item.cutomer_id} value={item.customer_id}>
                      {item.customer_name}
                    </Option>
                  ))}
              </AutoComplete>
            </Form.Item>
            <Divider />

            {/*-----------------*/}

            {selectedCutomer && (
              <div
                style={{
                  borderTop: "0px solid #eee",
                  marginTop: "5px",
                  marginBottom: "5px",
                }}
              >
                <table className='sell-customer-select-table'>
                  <tbody>
                    <tr>
                      <th style={{ padding: "5px !important" }}>
                        <i style={{ marginTop: "15px" }}><UserOutlined /></i>
                      </th>
                      <th style={{ padding: "5px !important" }}>
                        <b>{selectedCutomer.customer_name}</b>
                        <br />
                        <small>
                          {selectedCutomer.customer_email} |
                          {selectedCutomer.customer_phone}
                        </small>
                      </th>
                      <th style={{ padding: "5px !important" }}>
                        <button className='customer-del-btn-pull-right'>
                          <DeleteOutlined
                            className='customer-del-btn-icon'
                            onClick={handleCustomerDelete}
                          />
                        </button>
                      </th>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}

            {/*-----------------*/}

            <Divider />

            {/* Table */}
            <div className='table'>

              {!WomensWearAccountCheck &&
                <SellNestedProductsTable
                  tableData={productsTableData}
                  //tableDataLoading={loading}
                  checkCashier={registerScopeFilter(localStorageData.user_info)}
                  onChangeProductsData={handleChangeProductsData}
                  tableType='register_sell'
                />}

              {WomensWearAccountCheck &&
                <SellNestedProductsTableUpdatedTemplate
                  tableData={productsTableData}
                  //tableDataLoading={loading}
                  checkCashier={registerScopeFilter(localStorageData.user_info)}
                  onChangeProductsData={handleChangeProductsData}
                  tableType='register_sell'
                  isWomensWearAccount={WomensWearAccountCheck}
                />}

            </div>
            {/* Table */}

            <Divider />

            <div className='cost'>
              <div className='cost__wrapper'>
                <div className='cost__left'>
                  <div className='cost__box cost__text-border'>
                    <h3>Subtotal</h3>
                    <span>{saleInvoiceData && saleInvoiceData.sub_total}</span>
                  </div>

                  <Form.Item label='Discount' name='discounted_value'>
                    <Input
                      placeholder='0'
                      //defaultValue={0}
                      addonAfter='%'
                      onBlur={handleDiscountChange}
                    />
                  </Form.Item>

                  <div className='cost__box cost__text-border'>
                    <h3>Tax</h3>
                    <span>{saleInvoiceData && saleInvoiceData.tax}</span>
                  </div>

                  <div className='cost__box'>
                    <Button
                      type='primary'
                      icon={<EditOutlined />}
                      onClick={showModal}
                      className='custom-btn custom-btn--primary'
                    >
                      MOP
                    </Button>
                    <span>{saleInvoiceData && saleInvoiceData.method}</span>
                  </div>

                  <Modal
                    title='Select mode of payment'
                    visible={isMopModalVisible}
                    onOk={handleOk}
                    onCancel={handleCancel}
                  >
                    <div className='modal__content'>
                      <Button
                        type='primary'
                        icon={<DollarCircleOutlined />}
                        className='u-width-100 custom-btn custom-btn--primary'
                        style={{ marginBottom: "1rem" }}
                        onClick={() => changeMethodOfPayment("Cash")}
                      >
                        Cash
                      </Button>
                      <br />

                      <Button
                        type='primary'
                        icon={<CreditCardOutlined />}
                        className='u-width-100 custom-btn custom-btn--primary'
                        style={{ marginBottom: "1rem" }}
                        onClick={() => changeMethodOfPayment("Credit Card")}
                      >
                        Credit Card
                      </Button>
                      <br />

                      <Button
                        type='primary'
                        icon={<EditOutlined />}
                        className='u-width-100 custom-btn custom-btn--primary'
                        style={{ marginBottom: "1rem" }}
                        onClick={() => changeMethodOfPayment("Online")}
                      >
                        Online
                      </Button>
                      <br />

                      <Button
                        type='primary'
                        icon={<EditOutlined />}
                        className='u-width-100 custom-btn custom-btn--primary'
                        style={{ marginBottom: "1rem" }}
                        onClick={() => changeMethodOfPayment("Customer Layby")}
                        disabled={(saleInvoiceData && saleInvoiceData.hasCustomer == false)}
                      >
                        Customer Layby
                      </Button>
                      <br />
                    </div>
                  </Modal>
                </div>
                <div className='cost__right'>
                  <Form.Item label='Paid' name='paid'>
                    <InputNumber
                      className='u-width-100'
                      //value={saleInvoiceData.payed}
                      onChange={handlePaidChange}
                      disabled={
                        saleInvoiceData && saleInvoiceData.method !== "Cash"
                      }
                    />
                  </Form.Item>

                  <div className='cost__box cost__text-border'>
                    <h3>Change</h3>
                    <span>
                      {saleInvoiceData &&
                        (
                          saleInvoiceData.payed -
                          (saleInvoiceData.total -
                            saleInvoiceData.discountAmount)
                        ).toFixed(2)}
                    </span>
                  </div>
                  
                  <div className='cost__box cost__text-border'>
                    <h3>Discounted Amount</h3>
                    <span>
                      {saleInvoiceData &&
                        (
                          saleInvoiceData.discountAmount && saleInvoiceData.discountAmount.toFixed(2)
                        )}
                    </span>
                  </div>

                </div>
              </div>
              <Form.Item>
                <Button
                  type='primary'
                  onClick={() => handlePayBill("close")}
                  className='cost__btn custom-btn custom-btn--primary'
                  disabled={
                    ( (saleInvoiceData &&
                    saleInvoiceData.products &&
                    saleInvoiceData.products.length < 1) ||
                    !networkStatus || !currentReceiptNumber )
                  }
                >
                  Enter Sale Amount(
                  {saleInvoiceData &&
                    (
                      saleInvoiceData.total - saleInvoiceData.discountAmount
                    ).toFixed(2)}
                  )
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
        {/* Right */}
      </div>


      {/* print sales overview */}
      {(!WomensWearAccountCheck) &&
      (saleInvoiceData && currentReceiptNumber && saleInvoiceData.products) && (
        <PrintSalesInvoiceTable
          user={localStorageData}
          invoice={saleInvoiceData}
          selectedOutletTemplateData={templateData}
          currentInvoiceNo={saleInvoiceData.currentInvoiceNumber}
        />
      )}

      {(WomensWearAccountCheck) &&
        (saleInvoiceData && currentReceiptNumber && saleInvoiceData.products) && (
          <PrintSalesInvoiceTableUpdatedTemplate
            user={localStorageData}
            invoice={saleInvoiceData}
            selectedOutletTemplateData={templateData}
            currentInvoiceNo={saleInvoiceData.currentInvoiceNumber}
            isWomensWearAccount={WomensWearAccountCheck}
          />
        )}
      {/* print sales overview */}

      

    </>
  );
}

export default Sell;

